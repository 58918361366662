.scaleBarContainer {
  height: 0.5rem;
  width: 100%;
  background: transparent;
  border-radius: 20rem;
  margin: 0.5rem;
  text-align: right;
}

.scaleBar {
  background: #1d9ba4;
  height: 100%;
  border-radius: 20rem;
  margin: 0 0 0 auto;
}
