.pageWrapper {
    max-width: 1320px;
    margin: 0 auto;
    padding:0 3.75rem;
}

.headerContainer {
    background: #242AC2;
    color: #fff;
    padding-top: 3.75rem;
}

.titleContainer {
    display: flex;
    margin-bottom: 1.875rem;
    align-items: center;
}


.row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -.5rem;
    margin-left: -.5rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    padding-right: .5rem;
    padding-left: .5rem;

}

.container {
    padding: 1.875rem 2.125rem;
    display: flex;
    align-items: flex-end;
    background: #454DE7;
    border-radius: 0.625rem 0.625rem 0 0;
}

.onboardingMsg {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 1rem;
    line-height: 4.37rem;
}

.footer {
    max-width: 1320px;
    margin: 1.87rem auto;
    padding: 3.75rem;
    color: #AFB0B0;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
}

.footer a:hover{
    color: #404040;
    text-decoration: underline;
}
