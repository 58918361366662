.title {
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0 1.25rem;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize;
}

.titleContainer .titleBadge {
    border: 1px solid #fff;
    padding: 0.5rem 1.25rem;
    border-radius: 20rem;
    font-weight: 600;
    opacity: 0.8;
}
