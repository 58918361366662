.toggleContainer {
    background: transparent;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggleWrapper {
    background: rgba(255, 255, 255, 0.1);
    width: auto;
    padding: 0.43rem;
    border-radius: 40rem;
    border: 1px solid rgba(64, 64, 64, 0.05);
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.05);
}

.button {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin: 0 0.5rem;
    border: none;
    padding: 0.62rem 1.25rem;
    border-radius: 40rem;
    background: transparent;
    color: #404040;
}

.button:hover,
.button:active,
.button:focus {
    color: #fff;
    background: #434DF0;
    outline: none;
}

.selected {
    color: #fff;
    background: #434DF0;
}
