.card {
    background: #F9FAFA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;
    padding: 2rem 0 0;
    margin-bottom: 1.25rem;
}

.title {
    padding: 0 1.75rem;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
}

.title span {
    font-weight: 700;
    color: #434DF0;
}
