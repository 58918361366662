.row {
    display: flex;
    align-items: center;
}

.th {
    color: #AFB0B0;
    font-size: 0.75rem;
    font-weight: 700;
}

.btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    width: 150px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
    margin: 0 1rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    border: none;
    padding: 0.62rem 1.25rem;
    border-radius: 40rem;
    background: #ffffff;
    color: #404040;
}

.btn:hover{
    color: #fff;
    background: #434DF0;
    cursor: pointer;
}

.btn:disabled{
    opacity: 0.5;
}

.btn:disabled:hover{
    cursor: default;
    background: #ffffff;
    color: #404040;
}

.pageLimit {
    margin: 0 0.5rem;
}
