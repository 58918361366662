.container {
    padding: 2rem 2rem 5rem;
    align-items: center;
}

.wrapper {
    text-align: center;
}

.p {
    font-size: 1.2rem;
    line-height: 2rem;
}

.p a {
    text-decoration: underline;
}

.ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 2rem 0;
}

.ul li {
    list-style-type: none;
    margin: 0 2rem;
}

.benefit-title {
    color: #434DF0;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 2rem 0;
}

.benefit-description {
    font-size: 1.0rem;
    line-height: 2rem;
}

.btn {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin: 2rem 0.5rem 0 0.5rem;
    padding: 0.62rem 1.25rem;
    border-radius: 40rem;
    color: #fff;
    background: #434DF0;
    border: 1px solid rgba(64, 64, 64, 0.05);
    transition: 0.3s;
}

.btn:hover{
    border: 1px solid rgba(67, 77, 240, 0.5);
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.5);
    color: #434df0;
    cursor: pointer;
}

.link{
    color: #434DF0;
    text-decoration: underline;
    margin-bottom: 2rem;
    font-weight: bold;
}
