.arrowTxt{
    text-align: right;
    font-size: 2rem;
    padding-top: 2rem;
    font-weight: bold;
}

.arrow{
    max-width: 70px;
    display: inline-block;
    margin-left: 1rem;
    transform: rotate(90deg);
}
