.container {
  padding: 1.875rem 2.125rem;
  display: flex;
  align-items: flex-end;
  background: #454DE7;
  border-radius: 0.625rem 0.625rem 0 0;
}
.rowMargin {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  margin: 0 2.81rem;
}
.row{
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}
.rowBlock{
  display: inline-block;
  align-items: flex-end;
}
.col {
  margin: 0 1rem;
}
.colSpan{
  margin: 0 0 0 1rem;
}

.label{
  font-weight: 600;
  color: #8A99F8;
  font-size: 1rem;
}

.h2Ratio{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 3.75rem;
  margin-top: 1rem;
  line-height: 4.37rem;
}

.h2{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 3.75rem;
  margin-top: 1rem;
  line-height: 4.37rem;
}

.span{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 3.75rem;
  margin-top: 1rem;
  line-height: 4.37rem;
  color: #8A99F8;
}

@media (max-width: 1270px) {
  .h2Ratio, .h2, .span{
    font-size: 2.75rem;
  }
}

@media (max-width: 1060px) {
  .h2Ratio, .h2, .span{
    font-size: 1.75rem;
    margin-top: 0;
  }
  .label{
    font-size: 0.5rem;
    line-height: 0.75rem;
  }
}
